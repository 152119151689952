import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textStyle } from '~/styles/textStyle';

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const logoImage = css`
  width: 200px;
`;

export const description = css`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const loginButton = {
  container: css`
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray5};
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${colors.gray7};
    }
  `,
  logo: css`
    width: 36px;
  `,
  description: css`
    color: ${colors.gray3};
    ${textStyle('l', 'bold')}
  `,
};

import { NextRouter } from 'next/router';
import { envManager } from '~/constants/envManager';

type IUseLoginBySSO = (
  router: NextRouter
) => {
  actions: {
    loginBySSO: () => void;
  };
};

export const useLoginBySSO: IUseLoginBySSO = (router) => {
  const loginBySSO = () => {
    // NOTE: axios経由でgetしてもredirectの処理されないのでベタ書き
    router.replace(`${envManager.publicEnv.apiBaseUrl}/admin/api/v1/sign_in/saml`);
  };

  return {
    actions: {
      loginBySSO,
    },
  };
};
